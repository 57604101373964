<style scoped>
  .dialog-con{
    padding: 5px 20px 20px;
    text-align: center;
    color: #202020;
    font-size: 1.05rem;
    font-family: "苹方 粗体";
  }
  .dialog-con h3{
    font-size: 1.1rem;
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
  }
  ul.dialog-con-role{
    overflow: hidden;
  }
  ul.dialog-con-role li{
    float: left;
    width: 30%;
    margin-right: 5%;
    margin-bottom: 10px;
  }
  ul.dialog-con-role li:nth-of-type(3n){
    margin-right: 0;
  }
  ul.dialog-con-role li a{
    display: block;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  ul.dialog-con-role li img {
    display: block;
    width: 100%;
    height: 100px;
  }
  ul.dialog-con-role li a.on::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: url("../../assets/image/selected03.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
<template>
  <div class="dialog-bg">
    <div class="dialog-box">
      <div class="dialog-con">
        <h3>选择角色</h3>
        <ul class="dialog-con-role">
          <li v-for="(item, index) in dialogRole" :key="index">
            <a :class="active === index ? 'on': ''" @click="changeRole(index)"><img :src="item.ugcCharacterpicPath + '?x-oss-process=image/crop,x_0,y_0,w_750,h_1000'"></a>
          </li>
        </ul>
      </div>
      <div class="dialog-btn">
        <button class="btn-cancel" v-if="dialogData.btn[0]" @click="dialogCancelFn">{{dialogData.btn[0]}}</button>
        <button class="btn-sure" v-if="dialogData.btn[1]" @click="dialogSureFn">{{dialogData.btn[1]}}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['dialogRole'],
  data () {
    return {
      dialogData: {
        btn: ['取消', '确定']
      },
      active: 0
    }
  },
  computed: {
    // activeNum: function () {
    //   return this.status
    // }
  },
  mounted () {},
  methods: {
    dialogCancelFn () {
      this.$emit('dialogCancel')
    },
    dialogSureFn () {
      this.$emit('dialogSureImg')
    },
    changeRole (index) {
      this.active = index
      this.$emit('dialogSltRole', this.active)
    }
  }
}
</script>
