<style scoped>
  html,body{
    height: 100%;
    overflow: hidden;
  }
  /*.fade-enter{*/
  /*opacity: 0;*/
  /*transform: scale(0.85, 0.85);*/
  /*}*/
  /*.fade-enter-to{*/
  /*transform: scale(1, 1);*/
  /*}*/
  /*.fade-enter-active{*/
  /*transition: 0.25s ease-out;*/
  /*}*/
  .fade-enter, .fade-leave{
    opacity: 0;
  }
  .fade-enter-active, .fade-leave-active{
    transition: 0.5s ease-out;
  }
  .flip-list-enter-active, .flip-list-leave-active {
    transition: all 1s;
  }
  .flip-list-enter, .flip-list-leave-active {
    opacity: 0;
  }
  .img-enter{
    opacity: 0;
    transform: scale(0.85, 0.85);
  }
  .img-enter-to{
    transform: scale(1, 1);
  }
  .img-enter-active{
    transition: 0.35s ease-out;
  }
  .intro-enter{
    opacity: 0;
  }
  .intro-enter-to{
    opacity: 0;
  }
  .intro-enter-active{
    transition: 0.4s ease-out;
  }
  .role-img_ava{
    float: right!important;
  }
  .role-dialog1{
    float: right;
  }
  .role-intro-box1{
    margin-right: 70px;
    margin-left: 0!important;
  }
  .look-box.showType2 .role-look .role-intro-str1::before {
      right: -8px;
      border-left: 10px solid #fff;
      left: initial;
      border-right: initial;
  }
  .role-aside_Online_chat {
    padding: 5px 13px 5px 9px;
    text-align: center;
    width: 243px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 16px;
    /* margin-top: 28px; */
    margin-bottom: 7px;
  }

  .role-aside-con_Online_chat>p {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
  }
  /* .role-look-auto{
    margin-bottom: 90px;
  } */
  .role-look .role-cg p, .role-aside p {
    /* min-height: 10%;
        max-height: 50%; */
        overflow: auto;
        max-height: 50%;
  }
</style>
<template>
  <div class="chapter-look">
    <header-title :titleCon="chapterInfo.chapterName" @titleLeftBtn="titleFnReturn" style="position: fixed;top: 0px;left: 0px;right: 0px;z-index: 1002;"></header-title>
    <!--<div class="chapter-look-box" id="chapterLookBox" ref="chapterLookBox" :style="{backgroundImage:'url(' + chapterInfo.chapterBg.backpicPath + ')'}">-->
    <div class="chapter-look-box" id="chapterLookBox" ref="chapterLookBox">
      <div class="chapter-bg">
        <transition name="flip-list" appear>
          <img v-if="chapterInfo.chapterBg.backpicPath !== ''" :src="chapterInfo.chapterBg.backpicPath">
        </transition>
      </div>
      <a class="look-close look-close-str" @click.stop="closeLook">关闭预览</a>
      <audio :src="chapterInfo.chapterBgm.musicPath" v-if="chapterInfo.chapterBgm !== null" autoplay loop></audio>
      <div v-if="chapterInfo.showType === 0" class="look-box showType0" @click="read()">
        <transition-group>
          <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
            <div v-if="index === roleIndex">
              <!--角色对白-->
              <div class="role-dialog" v-if="item.contentType === 0">
                <transition name="img" appear>
                  <div class="role-img" v-if="item.ugcCharacter.ugcCharacterpicPath !== ''"  :style="'top:'+ roleTop + 'px'">
                    <img :src="item.ugcCharacter.ugcCharacterpicPath" />
                  </div>
                </transition>
                <transition name="intro" appear>
                  <div v-if="item.ugcCharacter.characterName !== ''">
                    <div class="role-intro-box">
                      <div><span>{{item.ugcCharacter.characterName}}</span></div>
                      <div class="role-intro-str" v-if="item.dialog !== ''">
                        <p class="intro-p">{{item.dialog}}</p>
                        <label class="hide" ref="introspan">{{item.dialog}}</label>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!--cg-->
              <div class="role-cg" v-if="item.contentType === 1">
                <div v-if="index === 0">
                  <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                </div>
                <transition name="flip-list" appear>
                  <div v-if="index > 0" class="role-cg-bg">
                    <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                  </div>
                </transition>
                <transition name="intro" appear>
                  <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
                </transition>
              </div>
              <!--旁白-->
              <div class="role-aside" v-if="item.contentType === 2">
                <div class="role-aside-con">
                  <p>{{item.aside}}</p>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div v-if="chapterInfo.showType === 1" class="look-box showType1" @click="read()">
        <!--<transition-group name="fade" tag="p">-->
        <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
          <div v-if="index === roleIndex">
            <!--角色对白-->
            <div class="role-dialog" v-if="item.contentType === 0">
              <div class="role-img">
                <!--<img :src="item.ugcCharacter.ugcCharacterpicPath  + '?x-oss-process=image/crop,x_75,y_00,w_600,h_937'" />-->
                <transition name="fade" appear>
                  <div class="showType1Img" v-if="item.ugcCharacter.ugcCharacterpicPath !== ''">
                    <div v-if="index === roleIndex">
                      <transition name="img" appear>
                        <img :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_100,y_10,w_550,h_750'" />
                      </transition>
                    </div>
                  </div>
                </transition>
                <transition name="intro" appear>
                  <div class="role-intro-box" v-if="item.ugcCharacter.characterName !== ''">
                    <div><span>{{item.ugcCharacter.characterName}}</span></div>
                    <div class="role-intro-str" v-if="item.dialog !== ''">
                      <p>{{item.dialog}}</p>
                      <label class="hide">{{item.dialog}}</label>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <!--cg-->
            <div class="role-cg" v-if="item.contentType === 1">
              <div v-if="index === 0">
                <img :src="item.ugcCgModel.ugcCgVo.cgPath">
              </div>
              <transition name="fade" appear>
                <div v-if="index > 0" class="role-cg-bg">
                  <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                </div>
              </transition>
              <transition name="intro" appear>
                <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
              </transition>
            </div>
            <!--旁白-->
            <div class="role-aside" v-if="item.contentType === 2">
              <div class="role-aside-con">
                <p>{{item.aside}}</p>
              </div>
            </div>
          </div>
        </div>
        <!--</transition-group>-->
      </div>
      <div v-if="chapterInfo.showType === 2">
        <div class="look-box showType2" @click="read()">
          <div class="role-look-box" style="overflow: auto;" id="lookautobox">
            <div calss="role-look-auto" id="lookauto" style="margin-bottom: 100px;">
              <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
                <div v-if="index <= roleIndex">
                  <!--角色对白 又-->
                  <div class="role-dialog " v-if="item.contentType === 0&&item.contentLocation === 0">
                    <div class="role-img" >
                      <!--<img :src="item.ugcCharacter.ugcCharacterpicPath  + '?x-oss-process=image/crop,x_75,y_00,w_600,h_937'" />-->
                      <img :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_170,y_20,w_400,h_400'" />
                      <div class="role-intro-box">
                        <div class="role-name-str"><span>{{item.ugcCharacter.characterName}}</span></div>
                        <div class="role-intro-str">
                          <p>{{item.dialog}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 角色对白 左 -->
                  <div class="role-dialog role-dialog1" v-if="item.contentType === 0&&item.contentLocation === 1">
                    <div class="role-img" >
                      <!--<img :src="item.ugcCharacter.ugcCharacterpicPath  + '?x-oss-process=image/crop,x_75,y_00,w_600,h_937'" />-->
                      <img class="role-img_ava" :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_170,y_20,w_400,h_400'" />
                      <div class="role-intro-box role-intro-box1">
                        <div class="role-name-str"><span>{{item.ugcCharacter.characterName}}</span></div>
                        <div class="role-intro-str role-intro-str1">
                          <p>{{item.dialog}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--cg-->
                  <div class="role-cg" v-if="item.contentType === 1">
                    <div v-if="index === roleIndex">
                      <div v-if="index === 0">
                        <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                      </div>
                      <transition name="fade" appear>
                        <div v-if="index > 0" class="role-cg-bg">
                          <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                        </div>
                      </transition>
                      <transition name="intro" appear>
                        <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
                      </transition>
                    </div>
                  </div>
                  <!--旁白-->
                  <!-- <div class="role-aside" v-if="item.contentType === 2">
                    <div class="role-aside-con" v-if="index === roleIndex">
                      <p>{{item.aside}}</p>
                    </div>
                  </div> -->
                <div class="role-aside_Online_chat" v-if="item.contentType === 2">
                  <div class="role-aside-con_Online_chat">
                    <p>{{item.aside}}</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title.vue'
export default {
  components: {
    headerTitle
  },
  props: [
    'chapterInfo', 'chapterInfoEdit'
  ],
  data () {
    return {
      totalIndex: 0,
      roleTotalIndex: 0, // 子章节跳转到打普通章节的第几章
      roleIndex: 0, // 正在阅读第几个角色
      roleTop: '', // 顶部距离
      chapterType: 0, // 是否子章节
      strIndex: 0 // 打印字
    }
  },
  computed: {
  },
  created () {
    this.roleIndex = 0
    // this.chapterId = this.$route.query.chapterId
    this.totalIndex = this.chapterInfo.roleList.length
    // console.log(this.chapterInfo)
    // document.getElementById('chapterLook').style.height = window.screen.height + 'px'
  },
  mounted () {
    this.roleTopFn()
  },
  updated () {
    // this.typingStr()
  },
  methods: {
    typingStr () {
      this.strIndex = 0
      this.typing()
    },
    typing () {
      // console.log(document.querySelector('.role-intro-str label'))
      // console.log(document.querySelector('.role-intro-str label').innerHTML)
      var strShow = ''
      let strHide = document.querySelector('.role-intro-str label').innerHTML
      if (this.strIndex <= strHide.length) {
        strShow = strHide.slice(0, this.strIndex++)
        document.querySelector('.role-intro-str p').innerHTML = strShow
        this.timer = setTimeout(() => {
          this.typing()
        }, 150)
      } else {
        clearTimeout(this.timer)
      }
    },
    titleFnReturn () {
      this.$router.go(-1)
    },
    goRoleIndex () {
      if (this.roleIndex < this.chapterInfo.roleList.length - 1) {
        this.roleIndex++
      } else {
        if (this.$route.query.lookType === 0) {
          this.$toast('本章完结')
        } else if (this.$route.query.lookType === 1) {
          this.$toast('本书完结')
        }
      }
    },
    // 点击阅读
    read () {
      if (this.chapterInfo.roleList.length > 0) {
        if (this.chapterInfo.roleList[this.roleIndex].ugcSubChapter !== null) { // 是否子章节
          this.roleTotalIndex = this.roleIndex + 1
          let that = this
          that.getChapterCon(that.chapterInfo.roleList[that.roleIndex].ugcSubChapter.subChapterId, 1, 0)
        } else {
          if (this.roleIndex < this.chapterInfo.roleList.length - 1) { // 普通章节不是最后一个角色
            this.roleIndex++
            // this.typingStr()
            let div1 = document.getElementById('lookautobox')
            let div2 = document.getElementById('lookauto')
            div1.scrollTop = div2.scrollHeight + '1000'
            // console.log(div1.scrollTop, div2.scrollHeight + '1000')
          } else {
            this.lastReadGo()
          }
        }
      } else {
        this.lastReadGo()
      }
      if (this.showType === 2) {
        let div1 = document.getElementById('lookautobox')
        let div2 = document.getElementById('lookauto')
        div1.scrollTop = div2.scrollHeight + 50
      }
    },
    // 普通与子章节最后角色跳转判断
    lastReadGo () {
      if (this.chapterType === 1) {
        if (this.roleTotalIndex < this.totalIndex) {
          this.chapterInfo = JSON.parse(this.chapterInfoEdit)
          this.chapterType = 0
          this.roleIndex = this.roleTotalIndex
          // this.getChapterCon(this.chapterId, 0, this.roleTotalIndex)
        } else {
          this.$toast('本章完结')
        }
      } else {
        this.$toast('本章完结')
      }
    },
    // 获取阅读页信息
    getChapterCon (chapterId, chapterType, roleIndex) {
      this.axios({
        method: 'get',
        url: '/v1/previewChapter',
        params: {
          chapterId: chapterId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.chapterType = chapterType
          this.roleIndex = roleIndex
          this.chapterInfo.chapterName = res.data.content.chapterTitle
          this.chapterInfo.chapterBg = res.data.content.ugcChapterContent.ugcBackpicVo || {}
          this.chapterInfo.chapterBgm = res.data.content.ugcChapterContent.ugcMusicVo || {}
          this.chapterInfo.roleList = res.data.content.ugcChapterContent.chapterDialogs
          this.chapterInfo.showType = res.data.content.showType
          if (chapterType === 0) {
            this.totalIndex = res.data.content.ugcChapterContent.chapterDialogs.length
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 关闭预览
    closeLook () {
      this.$emit('closeLook')
    },
    // 半身像显示屏幕兼容
    roleTopFn () {
      var winW = this.$refs.chapterLookBox.clientWidth
      var winH = this.$refs.chapterLookBox.clientHeight
      if (winW * 1.3 * 1.25 > winH - 60) {
        this.roleTop = 20
      } else {
        this.roleTop = ''
      }
      // console.log('winW：' + winW + '，winH' + winH)
      // console.log(this.roleTop)
    }
  }
}
</script>
