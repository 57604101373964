<style scoped>
  .form-item{
    position: relative;
  }
  .form-item .icon-right{
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .form-item .role-list{
    padding-bottom: 30px;
  }

  .form-item .role-box {
    padding-bottom: 10px;
    position: relative;
    overflow: hidden;
    font-size: 0.8rem;
  }

  .form-item .role-box .role-dialog {
    margin-bottom: 10px;
  }

  .form-item .role-box .role-aside,
  .form-item .role-box .role-cg {
    margin-bottom: 0px;
  }

  .role-box .role-img,
  .role-box .role-cg-img {
    width: 80px;
    float: left;
    text-align: center;
  }

  .role-box .role-img img,
  .role-box .role-cg-img img {
    width: 80px;
    height: 100px;
  }

  .role-box .role-img p {
    width: 80px;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .role-box .role-intro {
    margin-left: 90px;
  }

  .role-box textarea {
    height: 100px;
  }

  .role-box .role-btn {
    text-align: right;
    margin-top: 3px;
  }

  .role-box .role-btn button {
    height: 17px;
    line-height: 20px;
    /* border: 1px solid #4e43e1; */
    color: #4e43e1;
    border-radius: 3px;
    background: rgba(255,255,255,0);
    padding: 0 4px;
    /* margin-left: 5px; */
    /* font-size: 0.75rem; */
    font-size: 12px;
  }

  .role-box .role-intro button.role-son {}

  .role-box .role-btn button.role-del {
    /* background: url("../../assets/image/icon_del02.png") 5px center no-repeat; */
    /* background-size: 12px 14px; */
    /* padding-left: 20px; */
  }

  .role-box .role-btn a {
    display: inline-block;
    width: 40px;
    text-align: center;
    color: #4e43e1;
  }

  .role-box .role-intro .role-son-con {
    margin-top: 5px;
    color: #666;
    font-size: 0.75rem;
  }

  .role-box .role-intro .role-son-con a {
    float: right;
    color: #4e43e1;
  }

  .role-total {
    height: 57px;
    padding: 8px 0px 0px 11px;
    margin: 0 -15px;
    border-top: 1px solid #ddd;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: RGBA(225, 227, 249, 1)!important;
    -webkit-box-sizing: border-box;
  }

  .role-total .role-total-box {
    overflow: hidden;
    /*height: 46px;*/
  }

  .role-total ul {
    /* height: 53px; */
    white-space: nowrap;
    overflow-x: auto;
  }

  .role-total ul::-webkit-scrollbar {
    display: none
  }

  .role-total ul li {
    /* width: 45px; */
    /*float: left;*/
    display: inline-block;
    vertical-align: top;
    /* margin-right: 3px; */
    text-align: center;
  }

  .role-total ul li.first {
    margin-right: 10px;
  }
.role-total ul li.second {
    margin-right: 6px;
  }
  .role-total ul li.third {
      margin-right: 5px;
    }
    .role-total ul li.addrole {
        margin-right: 5px;
      }
  .role-total ul li a {
    display: block;
    font-size: 0.7rem;
    text-align: center;
    color: #444;
  }

  .role-total ul li img {
    /* display: block; */
    width: 35.29px;
    height: 35.29px;
    /* margin: 0 auto; */
    border-radius: 50%;
    /*border: 1px solid #bfbfbf;*/
  }

  .role-total ul li.first img {
    width: 35.29px;
    height: 35.29px;
    border: none;
  }

  .role-total ul li span.img {
    display: block;
    width: 35.29px;
    height: 35.29px;
    line-height: 35.29px;
    border-radius: 50%;
    color: #fff;
    font-size: 0.9rem;
  }

  .role-total ul li span.text {
    display: block;
    width: 34px;
    height: 12.1px;
    line-height: 12.1px;
    background: #9398d8;
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: -5.29px;
    transform: scale(1, 0.98);
    /* margin-bottom: 3px; */
  }
  .slide-move {
    transition: all 0.2s;
  }
  .role-btn{
    overflow: hidden;
  }
  .role-btn>button {
    float: right;
  }
  .header .section.title{
        /* height: initial!important; */
        /* line-height: 50px; */
        /* position: initial!important; */
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
    .inputstyll{
     background: rgba(255,255,255,0.5);
     border: initial;
    }
    .role-son-con>span{
    /* color: #4e43e1; */
    font-size: 12px;
    float: left;

    }
    .role-son-con>button{
    color: #4e43e1!important;
    font-size: 12px!important;
    float: right;
    background: rgba(255,255,255,.0);
    }
    @media screen and (max-width: 360px) {
.role-son-con>span{
     display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
                width: 163px;

    }
  }
</style>
<template>
  <div>
    <div class="addCon">
      <header-title :titleCon="ugcChapter.chapterTitle" titleBtnStrLf="保存" titleBtnStr="预览" @titleLeftBtn="titleFnReturn"  @titleFnBtnLf="saveChapter" @titleFnBtn="preview"></header-title>
      <div class="content book-edit-form form-block" style="padding-bottom: 30px;">
        <!-- 初始背景 -->
        <div class="form-item">
          <label>初始背景</label>
          <input class="inputstyll" type="text" readonly v-model="ugcChapterContent.ugcBackpicVo.backpicTitle" @click="addBg" placeholder="请添加初始背景"/>
          <img class="icon-right" src="../../assets/image/icon_right.png" height="18">
        </div>
        <!-- 初始音乐 -->
        <div class="form-item">
          <label>初始音乐</label>
          <input class="inputstyll" type="text" readonly v-model="ugcChapterContent.ugcMusicVo.musicTitle" @click="addBgm" placeholder="请添加初始音乐"/>
          <img class="icon-right" src="../../assets/image/icon_right.png" height="18">
        </div>
        <!-- 内容创作 -->
        <div class="form-item">
          <label>内容创作</label>
          <div class="role-list">
            <transition-group name="slide">
              <div class="role-box" v-for="(item, index) in ugcChapterContent.chapterDialogs" :key="index">
                <!--角色对白-->
                <div v-if="item.contentType === 0" class="role-dialog">
                  <div class="role-img">
                    <img :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_0,y_0,w_750,h_937'" @click="changeRole(index)">
                    <p>{{item.ugcCharacter.characterName}}</p>
                  </div>
                  <div class="role-intro">
                    <textarea class="inputstyll" placeholder="请输入角色对白" v-model="item.dialog" maxlength="500"></textarea>
                    <div class="role-btn">
                      <button v-if="chapterType === 0" class="role-son" @click="changeSon(index)">选择子剧情</button>
                      <button class="role-del" @click="delRole(index, 0)">删除</button>
                      <button type="button" @click="goDown(index)" v-if="index < ugcChapterContent.chapterDialogs.length - 1">下移</button>
                      <button type="button" @click="goUp(index)" v-if="index > 0">上移</button>
                      <button type="button" v-show="(item.contentLocation === 0||item.contentLocation === null) && ugcChapter.showType === 2" @click="goright(item, index)">左边</button>
                      <button type="button" v-show="item.contentLocation === 1 && ugcChapter.showType === 2" @click="goleft(item, index)">右边</button>
                    </div>
                    <div class="role-son-con" v-if="item.ugcSubChapter !== null">
                      <span>章节跳转：(子剧情){{item.ugcSubChapter.subChapterName}}</span>
                      <button @click="delSon(index)">删除</button>
                    </div>
                  </div>
                </div>
                <!--CG-->
                <div v-if="item.contentType === 1" class="role-cg">
                  <label>CG</label>
                  <div class="role-cg-img">
                    <img v-if="item.ugcCgModel.ugcCgVo.cgPath" :src="item.ugcCgModel.ugcCgVo.cgPath + '?x-oss-process=image/crop,x_0,y_0,w_750,h_937'" @click="goCg(index)">
                    <img v-else src="../../assets/image/CG@2x.png" @click="goCg(index)"/>
                    <p>{{item.ugcCgModel.ugcCgVo.cgTitle}}</p>
                  </div>
                  <div class="role-intro">
                    <textarea class="inputstyll" placeholder="请输入文本" v-model="item.ugcCgModel.text" maxlength="500"></textarea>
                    <div class="role-btn">
                      <button class="role-del" @click="delRole(index, 1)">删除</button>
                      <button @click="goDown(index)" v-if="index < ugcChapterContent.chapterDialogs.length - 1">下移</button>
                      <button @click="goUp(index)" v-if="index > 0">上移</button>
                    </div>
                  </div>
                </div>
                <!--旁白-->
                <div v-if="item.contentType === 2" class="role-aside">
                  <label>旁白</label>
                  <div class="role-aside-intro">
                    <textarea class="inputstyll" placeholder="请输入文本" v-model="item.aside" maxlength="500"></textarea>
                    <div class="role-btn">
                      <button class="role-del" @click="delRole(index, 2)">删除</button>
                      <button @click="goDown(index)" v-if="index < ugcChapterContent.chapterDialogs.length - 1">下移</button>
                      <button @click="goUp(index)" v-if="index > 0">上移</button>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
            <div class="role-box" v-if="ugcChapterContent.chapterDialogs.length <= 0">
              <div class="role-box-null" style="text-align: center; padding: 20px 0;">
                <img src="../../assets/image/null_role.png" width="90" style="position: relative; left:-5px;"/>
                <p style="color: #bfc2e7; margin-top: 10px;line-height: 20px;">
                  暂无内容<br/>选择底部头像，添加角色
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="role-total">
          <div class="role-total-box">
            <ul>
              <li class="first">
                <a @click="goRoleManage">
                  <img src="../../assets/image/icon_addrole01.png">
                  <!-- <span class="text" style="background: none"></span> -->
                </a>
              </li>
              <li class="second">
                <a @click="addAside">
                  <!--<img src="../../assets/image/PB.png" width="42" height="42" style="border: none;">-->
                  <span class="img" style="background: #efa865;">旁白</span>
                  <!--<span class="text" style="background: none"></span>-->
                </a>
              </li>
              <li class="third">
                <a @click="addCg">
                  <!--<img src="../../assets/image/CG.png" width="42" height="42" style="border: none;">-->
                  <span class="img" style="background: #ef7b65;">CG</span>
                  <!--<span class="text" style="background: none"></span>-->
                </a>
              </li>
              <li v-for="(item, index) in totalRoleList" :key="index" class="addrole">
                <a>
                  <img :src="item.ugcCharacterpicPath + '?x-oss-process=image/crop,x_100,y_0,w_550,h_550'" :alt="item.characterName" @click="addRole(index)"/>
                  <span class="text">{{item.characterName}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <base-dialog v-show="dialogShow" :dialogContent="dialogContent" @dialogCancel="dialogCancelFn" @dialogSure="dialogSureFn"></base-dialog>
      <img-dialog v-show="dialogShowImg" :dialogRole="dialogRole" @dialogSltRole="dialogSltRoleFn" @dialogCancel="dialogCancelFn" @dialogSureImg="dialogSureImgFn"></img-dialog>
    </div>
    <look v-if="isLook" :titlecon="ugcChapter.chapterTitle" :chapterInfo="chapterInfo" :chapterInfoEdit="chapterInfoEdit" @closeLook="closeLook" @touchmove.prevent style="position: fixed;top: 0;left: 0;right: 0;background: #fff;z-index: 1002;"></look>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import headerTitle from '../../components/header/header-title-transparent.vue'
import baseDialog from '../../components/dialog/base-dialog.vue'
import imgDialog from '../../components/dialog/img-dialog.vue'
import look from '../../components/look/look.vue'
import qs from 'qs'
// var saveFlag = true
export default {
  components: {
    headerTitle,
    baseDialog,
    imgDialog,
    look
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      isReClick: true, // 是否重复提交
      isLook: false, // 预览是否展示
      type: 'add', // 编辑或新增
      showType: 0, // 章节表现形式0、1、2
      chapterType: 0, // 普通1，子章节2
      chapterId: '',
      dialogShow: false, // 通用弹框
      dialogShowImg: false, // 替换角色弹框
      activeDialogRole: 0, // 替换第几个角色
      activeChangeRole: 0, // 角色弹框内选中第几个0-5
      dialogContent: '确定删除该角色对白吗？',
      dialogRole: [], // 最近使用角色弹框
      delRoleIndex: 0, // 删除第几个角色
      delSonIndex: 0, // 删除第几个子剧情
      ugcChapter: {}, // 章节信息
      ugcChapterContent: {
        ugcBackpicVo: {},
        ugcMusicVo: {},
        chapterDialogs: []
      },
      totalRoleList: [],
      chapterInfo: {
        chapterId: '',
        chapterName: '',
        showType: 0,
        chapterType: 0,
        chapterBg: {},
        chapterBgm: {},
        roleList: []
      },
      chapterInfoEdit: ''
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // console.log(this.$store.state.chapterInfo)
    this.chapterType = parseInt(this.$route.query.chapterType)
    // 章节信息
    this.axios({
      method: 'get',
      url: '/v1/chapter/toEditPage',
      params: {
        ugcBookId: this.$route.query.bookId,
        chapterId: this.$route.query.chapterId,
        chapterType: this.$route.query.chapterType,
        type: this.$route.query.type
      }
    }).then((res) => {
      // console.log(res)
      if (res.data.head.flag === 0) {
        // console.log(res.data.content)
        // console.log(this.$store.state.chapterInfo)
        this.totalRoleList = res.data.content.chapterCharacters || []
        this.ugcChapter = res.data.content.ugcChapter || {}
        this.type = res.data.content.type
        if (res.data.content.type === 'add') {
          this.chapterId = res.data.content.chapterId
          // 章节模式
          if (this.$store.state.chapterInfo.showType >= 0) {
            this.showType = this.$store.state.chapterInfo.showType
          } else {
            this.showType = 0
          }
        } else {
          this.chapterId = res.data.content.ugcChapter.id
          this.showType = res.data.content.ugcChapter.showType
        }
        // 加载时章节有内容
        if (res.data.content.ugcChapterContent !== null) {
          this.ugcChapterContent = res.data.content.ugcChapterContent || {}
          this.ugcChapterContent.ugcBackpicVo = res.data.content.ugcChapterContent.ugcBackpicVo || {}
          this.ugcChapterContent.ugcMusicVo = res.data.content.ugcChapterContent.ugcMusicVo || {}
        }
        // 角色编辑过
        if (this.$store.state.chapterInfo.roleList.length > 0) {
          this.ugcChapterContent.chapterDialogs = this.$store.state.chapterInfo.roleList
          let that = this
          if (this.$route.query.roleId !== undefined) {
            this.ugcChapterContent.chapterDialogs.forEach(function (ele, idx) {
              if (ele.ugcCharacter.id === that.$route.query.roleId) {
                that.totalRoleList.forEach(function (element, index) {
                  if (element.id === that.$route.query.roleId) {
                    that.ugcChapterContent.chapterDialogs[idx].ugcCharacter = that.totalRoleList[index]
                  }
                })
              }
            })
          }
        }
        // 背景图编辑过
        if (this.$store.state.chapterInfo.chapterBg.backpicTitle || this.$store.state.chapterInfo.chapterBg.backpicTitle === '') {
          this.ugcChapterContent.ugcBackpicVo.backpicTitle = this.$store.state.chapterInfo.chapterBg.backpicTitle
          this.ugcChapterContent.ugcBackpicVo.id = this.$store.state.chapterInfo.chapterBg.id
          this.ugcChapterContent.ugcBackpicVo.backpicPath = this.$store.state.chapterInfo.chapterBg.backpicPath
        }
        // 背景音乐编辑过
        if (this.$store.state.chapterInfo.chapterBgm.musicTitle || this.$store.state.chapterInfo.chapterBgm.musicTitle === '') {
          this.ugcChapterContent.ugcMusicVo.musicTitle = this.$store.state.chapterInfo.chapterBgm.musicTitle
          this.ugcChapterContent.ugcMusicVo.id = this.$store.state.chapterInfo.chapterBgm.id
          this.ugcChapterContent.ugcMusicVo.musicPath = this.$store.state.chapterInfo.chapterBgm.musicPath
        }
        // cg编辑过
        // console.log(this.$store.state.chapterInfo)
        if (this.$store.state.sltCptCgActive.firstTypeId !== '') {
          let cgIndex = this.$store.state.chapterInfo.chapterCgIndex
          this.ugcChapterContent.chapterDialogs[cgIndex].ugcCgModel.ugcCgVo.cgTitle = this.$store.state.chapterInfo.chapterCg.cgTitle
          this.ugcChapterContent.chapterDialogs[cgIndex].ugcCgModel.ugcCgVo.id = this.$store.state.chapterInfo.chapterCg.id
          this.ugcChapterContent.chapterDialogs[cgIndex].ugcCgModel.ugcCgVo.cgPath = this.$store.state.chapterInfo.chapterCg.cgPath
          this.ugcChapterContent.chapterDialogs[cgIndex].ugcCgModel.ugcCgVo.sltCptCgActive = this.$store.state.sltCptCgActive
        }
        // 章节名称编辑过
        if (this.$store.state.chapterInfo.chapterName) {
          this.ugcChapter.chapterTitle = this.$store.state.chapterInfo.chapterName
        }
        if (this.$store.state.chapterSonEdit.length > 0) {
          var that = this
          this.$store.state.chapterSonEdit.forEach(function (ele, idx) {
            that.ugcChapterContent.chapterDialogs[that.$store.state.chapterSonEdit[idx].subIndex].ugcSubChapter = that.$store.state.chapterSonEdit[idx].ugcSubChapter
          })
        }
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  mounted () {
  },
  methods: {
    // 左移
    goleft (item, index) {
      // this.goleftr = 0
      this.ugcChapterContent.chapterDialogs[index].contentLocation = 0
      // console.log(item)
      // console.log(index)
    },
    // 右移
    goright (item, index) {
      this.goleftr = 1
      this.ugcChapterContent.chapterDialogs[index].contentLocation = 1
      // console.log(item)
      // console.log(index)
    },
    // 返回
    titleFnReturn () {
      this.clearChapterInfo()
      this.$router.push({ path: '/chapterManage', query: { bookId: this.$route.query.bookId } })
    },
    // 去背景库
    addBg () {
      this.saveChapterInfo()
      this.$router.push({ path: '/chapterBg', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
    },
    // 去音乐库
    addBgm () {
      this.saveChapterInfo()
      this.$router.push({ path: '/chapterBgm', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
    },
    // 去CG
    goCg (index) {
      this.$store.state.chapterInfo.chapterCgIndex = index
      if (this.$store.state.sltCptCgActive.firstTypeId === '') { // 未编辑过得cg
        localStorage.setItem('sltCptCgActive', JSON.stringify(this.ugcChapterContent.chapterDialogs[index].ugcCgModel.ugcCgVo.sltCptCgActive))
        localStorage.setItem('sltCgId', this.ugcChapterContent.chapterDialogs[index].ugcCgModel.ugcCgVo.id)
      }
      this.saveChapterInfo()
      this.$router.push({ path: '/chapterCg', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
    },
    changeSon (index) {
      this.saveChapterInfo()
      this.$router.push({ path: '/chapterSonList', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type, chapterSon: index } })
    },
    // 删除子剧情
    delSon (index) {
      this.dialogContent = '确定删除该子剧情吗？'
      this.dialogShow = true
      this.delSonIndex = index
    },
    // 去角色管理
    goRoleManage () {
      this.saveChapterInfo()
      this.$router.push({ path: '/roleManage', query: { bookId: this.$route.query.bookId, chapterId: this.chapterId, chapterType: this.$route.query.chapterType, chapterSet: 1, type: this.$route.query.type } })
    },
    // 删除角色对白
    delRole (index, type) {
      if (type === 0) {
        this.dialogContent = '确定删除该角色对白吗？'
      } else if (type === 1) {
        this.dialogContent = '确定删除该CG吗？'
      } else if (type === 2) {
        this.dialogContent = '确定删除该旁白吗？'
      }
      this.dialogShow = true
      this.delRoleIndex = index
    },
    // 弹框取消按钮
    dialogCancelFn () {
      this.dialogShow = false
      this.dialogShowImg = false
    },
    // 确定删除角色对白
    dialogSureFn () {
      if (this.dialogContent === '确定删除该角色对白吗？') {
        this.ugcChapterContent.chapterDialogs.splice(this.delRoleIndex, 1)
      } else if (this.dialogContent === '确定删除该子剧情吗？') {
        this.ugcChapterContent.chapterDialogs[this.delSonIndex].ugcSubChapter = null
      } else if (this.dialogContent === '确定删除该CG吗？') {
        this.ugcChapterContent.chapterDialogs.splice(this.delRoleIndex, 1)
      } else if (this.dialogContent === '确定删除该旁白吗？') {
        this.ugcChapterContent.chapterDialogs.splice(this.delRoleIndex, 1)
      }
      this.dialogShow = false
    },
    // 本章添加角色
    addRole (index) {
      // console.log(index)
      this.ugcChapterContent.chapterDialogs.push({
        ugcCharacter: this.totalRoleList[index],
        dialog: '',
        ugcSubChapter: null,
        aside: '',
        ugcCgModel: null,
        contentType: 0,
        contentLocation: 0
      })
      this.axios({
        method: 'get',
        url: '/v1/character/updTime',
        params: {
          chapterId: this.ugcChapter.id,
          characterId: this.totalRoleList[index].id
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 添加旁白
    addAside () {
      this.ugcChapterContent.chapterDialogs.push({
        ugcCharacter: null,
        dialog: null,
        ugcSubChapter: null,
        aside: '',
        ugcCgModel: null,
        contentType: 2
      })
    },
    // 添加CG
    addCg () {
      this.ugcChapterContent.chapterDialogs.push({
        ugcCharacter: null,
        dialog: null,
        ugcSubChapter: null,
        aside: '',
        ugcCgModel: {
          ugcCgVo: {
            id: '',
            cgTitle: '',
            cgPath: '',
            sltCptCgActive: null
          },
          text: ''
        },
        contentType: 1
      })
    },
    // 上移
    goUp (index) {
      let temp = this.ugcChapterContent.chapterDialogs[index - 1]
      Vue.set(this.ugcChapterContent.chapterDialogs, index - 1, this.ugcChapterContent.chapterDialogs[index])
      Vue.set(this.ugcChapterContent.chapterDialogs, index, temp)
    },
    // 下移
    goDown (index) {
      let temp = this.ugcChapterContent.chapterDialogs[index + 1]
      Vue.set(this.ugcChapterContent.chapterDialogs, index + 1, this.ugcChapterContent.chapterDialogs[index])
      Vue.set(this.ugcChapterContent.chapterDialogs, index, temp)
    },
    // 替换登场角色弹框
    changeRole (index) {
      this.activeChangeRole = index
      // 最近使用角色
      this.axios({
        method: 'get',
        url: '/v1/recentCharacters',
        params: {
          chapterId: this.$route.query.chapterId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.dialogRole = res.data.content.recentCharacters || []
        } else {
          this.$toast(res.data.head.desc)
        }
      })
      this.dialogShowImg = true
    },
    // 弹框最近使用切换
    dialogSltRoleFn (index) {
      this.activeDialogRole = index
    },
    // 确定替换角色
    dialogSureImgFn () {
      this.ugcChapterContent.chapterDialogs[this.activeChangeRole].dialog = ''
      this.ugcChapterContent.chapterDialogs[this.activeChangeRole].ugcCharacter = this.dialogRole[this.activeDialogRole]
      this.dialogShowImg = false
    },
    // 预览
    preview () {
      var chapterInfoEditData = {
        chapterId: this.$route.query.chapterId,
        chapterName: this.ugcChapter.chapterTitle,
        showType: this.showType,
        chapterType: 0,
        chapterBg: this.ugcChapterContent.ugcBackpicVo,
        chapterBgm: this.ugcChapterContent.ugcMusicVo,
        roleList: this.ugcChapterContent.chapterDialogs
      }
      this.chapterInfo = chapterInfoEditData
      // console.log(this.chapterInfo)
      this.isLook = true
      this.chapterInfoEdit = JSON.stringify(chapterInfoEditData)
    },
    // 关闭预览
    closeLook () {
      this.isLook = false
    },
    // 保存章节编辑信息
    saveChapterInfo () {
      this.$store.state.chapterInfo.chapterName = this.ugcChapter.chapterTitle
      this.$store.state.chapterInfo.chapterBg.backpicTitle = this.ugcChapterContent.ugcBackpicVo.backpicTitle
      this.$store.state.chapterInfo.chapterBg.id = this.ugcChapterContent.ugcBackpicVo.id
      this.$store.state.chapterInfo.chapterBg.backpicPath = this.ugcChapterContent.ugcBackpicVo.backpicPath
      this.$store.state.chapterInfo.chapterBgm.musicTitle = this.ugcChapterContent.ugcMusicVo.musicTitle
      this.$store.state.chapterInfo.chapterBgm.id = this.ugcChapterContent.ugcMusicVo.id
      this.$store.state.chapterInfo.chapterBgm.musicPath = this.ugcChapterContent.ugcMusicVo.musicPath
      this.$store.state.chapterInfo.roleList = this.ugcChapterContent.chapterDialogs
      this.$store.state.chapterInfo.showType = this.showType
      // this.$store.state.roleEdit = this.ugcChapterContent.chapterDialogs
      if (this.$store.state.sltCptBgActive.firstTypeId === '') {
        localStorage.setItem('sltCptBgActive', JSON.stringify(this.ugcChapterContent.ugcBackpicVo.sltCptBgActive))
        localStorage.setItem('sltBgId', this.ugcChapterContent.ugcBackpicVo.id)
      }
      if (this.$store.state.sltCptBgmActive.firstTypeId === '') {
        localStorage.setItem('sltCptBgmActive', JSON.stringify(this.ugcChapterContent.ugcMusicVo.sltCptBgmActive))
        localStorage.setItem('sltBgmId', JSON.stringify(this.ugcChapterContent.ugcMusicVo.id))
      }
    },
    // 清空编辑信息
    clearChapterInfo () {
      // this.$store.state.roleEdit = []
      this.$store.state.chapterInfo = { // 添加章节信息
        chapterName: '',
        chapterBg: {},
        chapterBgm: {},
        chapterCgIndex: -1,
        chapterCg: {},
        roleList: []
      }
      this.$store.state.chapterSonEdit = []
      localStorage.setItem('sltCptBgActive', '')
      localStorage.setItem('sltCptBgmActive', '')
      localStorage.setItem('sltCptCgActive', '')
      this.$store.state.sltCptBgActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.sltCptBgmActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
      this.$store.state.sltCptCgActive = {
        firstTypeIndex: '',
        firstTypeId: '',
        subTypeIndex: '',
        subTypeId: '',
        listActive: ''
      }
    },
    // 保存章节编辑
    saveChapter () {
      if (this.ugcChapter.chapterTitle === '' || this.ugcChapter.chapterTitle === undefined) {
        this.$toast('请输入章节名称')
      } else {
        var chapterId = ''
        if (this.type === 'add') {
          chapterId = this.chapterId
        } else {
          chapterId = this.ugcChapter.id
        }
        if (this.$store.state.sltCptBgActive.firstTypeId === '') {
          localStorage.setItem('sltCptBgActive', JSON.stringify(this.ugcChapterContent.ugcBackpicVo.sltCptBgActive))
        }
        if (this.$store.state.sltCptBgmActive.firstTypeId === '') {
          localStorage.setItem('sltCptBgmActive', JSON.stringify(this.ugcChapterContent.ugcMusicVo.sltCptBgmActive))
        }
        if (this.isReClick) {
          // console.log(this.type)
          this.axios({
            method: 'post',
            url: '/v1/chapter/save',
            data: qs.stringify({
              // authorId: sessionStorage.getItem('authorId'),
              ugcBookId: this.$route.query.bookId,
              type: this.type,
              chapterId: chapterId,
              chapterTitle: this.ugcChapter.chapterTitle,
              backpicId: this.ugcChapterContent.ugcBackpicVo.id,
              musicId: this.ugcChapterContent.ugcMusicVo.id,
              showType: this.showType,
              chapterType: this.$route.query.chapterType,
              chapterDialogs: JSON.stringify(this.ugcChapterContent.chapterDialogs),
              sltCptBgActive: localStorage.getItem('sltCptBgActive'),
              sltCptBgmActive: localStorage.getItem('sltCptBgmActive')
            })
          }).then((res) => {
            // console.log(res)
            if (res.data.head.flag === 0) {
              this.clearChapterInfo()
              if (this.$route.query.chapterType === '1') {
                this.$router.push({ path: '/chapterManage', query: { bookId: this.$route.query.bookId, chapterType: this.$route.query.chapterType } })
              } else {
                this.$router.push({ path: '/chapterManage', query: { bookId: this.$route.query.bookId } })
              }
              this.isReClick = false
            } else {
              this.$toast(res.data.head.desc)
            }
          })
        } else {
          this.$toast('重复点击了')
        }
      }
    }
  }
}
</script>
